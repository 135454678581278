<template>

  <div>
    <!-- Filters -->
    <!--        <owner-list-filters-->
    <!--                v-if="totalUsers > 0"-->
    <!--                :role-filter.sync="roleFilter"-->
    <!--                :plan-filter.sync="planFilter"-->
    <!--                :status-filter.sync="statusFilter"-->
    <!--                :role-options="roleOptions"-->
    <!--                :plan-options="planOptions"-->
    <!--                :status-options="statusOptions"-->
    <!--        />-->

    <b-card
        v-if="totalOwners === 0 && userData.practiceId === 0"
        no-body
        class="mb-0"
    >

      <div class="m-2 pb-4 text-center">
        <img src="@/assets/images/illustration/marketing.svg">
        <h3 class="mt-3">You don't have a practice yet. Would you like to create one?</h3>
        <b-button href="/practice/edit">Create Practice</b-button>
      </div>
    </b-card>

    <b-card
        v-if="totalOwners === 0 && userData.practiceId !== 0"
        no-body
        class="mb-0"
    >

      <div class="m-2 pb-4 text-center">
        <img src="@/assets/images/illustration/marketing.svg">
        <h3 class="mt-3">You don't have any owners yet. Would you like to add one?</h3>
        <b-button href="/owner/create">Create Owner</b-button>
      </div>
    </b-card>

    <!-- Table Container Card -->
    <b-card
        v-if="totalOwners > 0"
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h2>Total: {{ totalOwners }}/{{ totalPracticeOwners }}</h2>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
              />
              <b-button
                  variant="primary"
                  @click="goToOwnerCreate()"
              >
                <span class="text-nowrap">Add Owner</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          :items="owners"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'iab-owner', params: { id: data.item.id } }">{{ data.item.name }}</b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'iab-owner-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteOwner(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalOwners"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ownerService from "@core/services/owner/useOwner";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.getOwners();
  },
  data() {
    return {
      userData: {},
      owners: [],
      totalOwners: 0,
      totalPracticeOwners: 0,
      tableColumns: [
        {key: 'name', sortable: true},
        {key: 'title', sortable: true},
        {key: 'email', sortable: true},
        {key: 'actions'},
      ],
      perPage: 20,
      currentPage: 1,
      searchQuery: ""
    }
  },
  methods: {
    getOwners() {
      ownerService.getByPractice({
        'practice_id': this.userData.practiceId,
        'client_id': this.clientId
      }).then(response => {
        this.owners = response.data.owners;
        this.totalOwners = response.data.totalOwners;
        this.totalPracticeOwners = response.data.totalPracticeOwners;
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    },
    goToOwnerCreate() {
      window.location.href = '/owner/create';
    },
    deleteOwner(owner){
      ownerService.deleteOwner({
        'id': owner.id
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success!`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `You have successfully deleted this owner`,
          },
        })

        this.getOwners();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
