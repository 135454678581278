import BaseService from "@core/services/baseService";

export default class OwnerService extends BaseService {
    createOwner(...args){
        return this.axiosIns.post(this.formConfig.ownerEndpoint, ...args)
    }

    updateOwner(...args){
        return this.axiosIns.post(this.formConfig.ownerEndpoint+"/edit/"+args[0].id, ...args)
    }

    getOwner(...args){
        return this.axiosIns.get(this.formConfig.ownerEndpoint+"/"+args[0].id, ...args)
    }

    deleteOwner(...args){
        return this.axiosIns.delete(this.formConfig.ownerEndpoint+"/"+args[0].id)
    }

    getByPractice(...args){
        return this.axiosIns.get(this.formConfig.ownerEndpoint, {params: {'practice_id': args[0].practice_id}})
    }
}